import { useMutation } from "vue-query"
import { useContext } from "@nuxtjs/composition-api"
import { Severity } from "@sentry/types"

import { ApiPaths } from "@/enums"
import { CustomMutationHook } from "@/types/vue-query"

type AddDeviceParams = {
  device: string
  silent: boolean
}

export const useAddDeviceMutation: CustomMutationHook<AddDeviceParams, void> = (
  options,
) => {
  const { $axios } = useContext()
  const { app } = useContext()

  return useMutation(
    async (params: AddDeviceParams) => {
      const { data } = await $axios.post<void>(ApiPaths.ADD_DEVICE_PUSH, {
        ...params,
      })

      return data
    },
    {
      ...options,
      onError: (error) => {
        app.$sentry.captureEvent({
          message: error.message,
          level: Severity.Error,
        })
      },
    },
  )
}
