const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['check-role'] = require('../middleware/check-role.ts')
middleware['check-role'] = middleware['check-role'].default || middleware['check-role']

middleware['disallow-customer'] = require('../middleware/disallow-customer.ts')
middleware['disallow-customer'] = middleware['disallow-customer'].default || middleware['disallow-customer']

middleware['disallow-professional-created'] = require('../middleware/disallow-professional-created.ts')
middleware['disallow-professional-created'] = middleware['disallow-professional-created'].default || middleware['disallow-professional-created']

middleware['disallow-professional'] = require('../middleware/disallow-professional.ts')
middleware['disallow-professional'] = middleware['disallow-professional'].default || middleware['disallow-professional']

middleware['logged-out'] = require('../middleware/logged-out.ts')
middleware['logged-out'] = middleware['logged-out'].default || middleware['logged-out']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['route-interceptor'] = require('../middleware/route-interceptor.ts')
middleware['route-interceptor'] = middleware['route-interceptor'].default || middleware['route-interceptor']

middleware['select-role'] = require('../middleware/select-role.ts')
middleware['select-role'] = middleware['select-role'].default || middleware['select-role']

export default middleware
