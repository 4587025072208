



import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
} from "@nuxtjs/composition-api"

export default defineComponent({
  setup() {
    onBeforeMount(() => {
      document.body.classList.add("tw-bg-dark-secondary")
    })
    onBeforeUnmount(() => {
      document.body.classList.remove("tw-bg-dark-secondary")
    })
  },
})
