import { Middleware } from "@nuxt/types"
import { computed } from "@nuxtjs/composition-api"

const middleware: Middleware = ({ app, redirect, route }) => {
  const currentUser = computed(() => app.$accessor.user.currentUser)
  const currentUserRole = computed(() => app.$accessor.user.currentUserRole)

  if (
    currentUser.value &&
    !currentUserRole.value &&
    route.path !== app.$enums.AppPaths.SELECT_ROLE
  ) {
    redirect(
      app.localePath({
        path: app.$enums.AppPaths.SELECT_ROLE,
        query: { redirectPath: route.path },
      }),
    )
  }
}

export default middleware
