import { render, staticRenderFns } from "./darkMode.vue?vue&type=template&id=13d463e9&"
import script from "./darkMode.vue?vue&type=script&lang=ts&"
export * from "./darkMode.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports