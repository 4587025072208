import mobile from "is-mobile"

export function isMobile(): boolean {
  return mobile()
}

export function isiOSWrapper(): boolean {
  const wrapperUserAgent = "iOS-Healphant-Wrapper"

  return getUserAgent().includes(wrapperUserAgent)
}

export function getUserAgent(): string {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera || null

  if (!userAgent) throw new Error("Failed to look for user agent information.")
  return userAgent
}

export function iOSDevice(): boolean {
  return (
    ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}
