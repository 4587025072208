import { render, staticRenderFns } from "./customer.vue?vue&type=template&id=df66b0cc&scoped=true&"
import script from "./customer.vue?vue&type=script&lang=ts&"
export * from "./customer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df66b0cc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomerBottomNav: require('/workspace/components/Customer/CustomerBottomNav.vue').default,Cookies: require('/workspace/components/Cookies.vue').default})
