import { Plugin } from "@nuxt/types"

import * as api from "@/api"
import { keys } from "@/utils/keys"

const plugin: Plugin = (ctx, inject) => {
  const apiPlugin: Record<
    string,
    Record<string, (...args: any[]) => Promise<any>>
  > = {}

  keys(api).forEach((key) => {
    apiPlugin[key] = api[key](ctx.$axios)
  })

  inject("api", apiPlugin)
}

export default plugin
