









import { defineComponent } from "@nuxtjs/composition-api"

import Cookies from "@/components/Cookies.vue"
import CustomerBottomNav from "@/components/Customer/CustomerBottomNav.vue"
import LanguageSelector from "@/components/LanguageSelector.vue"

export default defineComponent({
  components: {
    Cookies,
    CustomerBottomNav,
    LanguageSelector,
  },
})
