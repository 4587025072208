import { Middleware } from "@nuxt/types"

const middleware: Middleware = ({ route, app }) => {
  const newPath = route.fullPath
  const currentPath = app.$accessor.misc.currentRoute.toString()

  if (!currentPath.length) {
    app.$accessor.misc.UPDATE_CURRENT_ROUTE(newPath)
  } else {
    app.$accessor.misc.UPDATE_CURRENT_ROUTE(newPath)
    app.$accessor.misc.UPDATE_LAST_ROUTE(currentPath)
  }
}

export default middleware
