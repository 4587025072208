export enum AppPaths {
  HOME = "/",
  SELECT_PROFESSIONAL = "/select-professional",
  LOGIN = "/login",
  LOGOUT = "/logout",
  SIGN_UP = "/sign-up",
  YOU_ARE_IN = "/you-are-in",
  SELECT_ROLE = "/select-role",
  USER_DETAILS = "/user-details",
  RESET_PASSWORD_REQUEST = "/reset-password/request",
  RESET_PASSWORD = "/reset-password",
  PROFESSIONAL_PROFILE = "/professional/profile",
  MY_CASES = "/professional/my-cases",
  PROFESSIONAL_CUSTOMERS = "/professional/customers",
  PROFESSIONAL_CUSTOMER_DETAILS = "/professional/customers/:id",
  SEARCH = "/search",
  PROFESSIONAL_CONTACT = "/p/:id",
  CHAT = "/chat/:id",
  CHAT_PAYMENT = "/chat/:id/payment",
  CUSTOMER_PROFILE = "/customer/profile",
  CUSTOMER_CASES = "/customer/my-cases",
  PROFESSIONAL = "/professional",
  CUSTOMER = "/customer",
  CHAT_INDEX = "/chat",
}
