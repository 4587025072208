






































import {
  computed,
  defineComponent,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api"

export default defineComponent({
  props: {
    showRefresh: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    info: {
      type: String,
      default: undefined,
    },
    moreInfo: {
      type: String,
      default: undefined,
    },
    showGoToHome: {
      type: Boolean,
      default: true,
    },
    buttonLink: {
      type: String,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const context = useContext()
    const router = useRouter()
    const getButtonText = computed(() => {
      return props.buttonText || context.app.i18n.t("global.backToHome")
    })

    const refresh = () => {
      router.go(0)
    }

    return {
      getButtonText,
      refresh,
    }
  },
})
