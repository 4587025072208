







































import { defineComponent } from "@nuxtjs/composition-api"

import BNav from "@/components/Professional/BNav.vue"
import useBottomNav from "@/composables/useBottomNav"
import useOneSignalSubscription from "@/composables/useOneSignalSubscription"
import { formatStrToHTML } from "@/utils/htmlFormatter"

export default defineComponent({
  components: { BNav },

  setup() {
    const { direction } = useBottomNav()
    useOneSignalSubscription()

    return {
      direction,
      formatStrToHTML,
    }
  },
})
