import { NuxtAppOptions } from "@nuxt/types"
import { AxiosError } from "axios"

import { ApiError, ApiErrorsList } from "@/types/models/ApiError"

export default function useMessageApiErrorI18n() {
  const formatErrorMessage = (
    error: AxiosError<ApiError>,
    app: NuxtAppOptions,
    component: string = "application",
  ): string => {
    const i18nKey = `errorMessages.${component}.${error.response?.data.message}`
    const message = error.response?.data.message
      ? app.i18n.t(i18nKey, app.i18n.locale).toString()
      : app.i18n.t("errorMessages.global.generic").toString()
    return message
  }

  const formatErrorList = (
    error: AxiosError<ApiErrorsList>,
    app: NuxtAppOptions,
  ): string[] => {
    const messageError: string[] = []
    error.response?.data.forEach((e) => {
      const field = app.i18n
        .t(`errorMessages.fields.${e.first}`, app.i18n.locale)
        .toString()
      const fieldI18n =
        field === `errorMessages.fields.${e.first}` ? e.first : field
      const messageI18n = app.i18n
        .t(`errorMessages.application.${e.second}`, app.i18n.locale)
        .toString()
      messageError.push(fieldI18n.concat(": ").concat(messageI18n))
    })
    return messageError
  }

  return { formatErrorMessage, formatErrorList }
}
