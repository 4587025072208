import { Middleware } from "@nuxt/types"

const middleware: Middleware = ({ app, route, redirect, store }) => {
  const hasUser = store.state.user.hasUser

  const authorization = app.$cookies.get(app.$enums.Cookies.AUTHORIZATION)
  if (!hasUser || !authorization) {
    const redirectPath = route.fullPath

    app.$accessor.user.clearSession()
    redirect({
      path: app.localePath(app.$enums.AppPaths.LOGIN),
      query: { redirectPath },
    })
  }
}

export default middleware
