






































































































import {
  computed,
  defineComponent,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api"
export default defineComponent({
  props: {
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { app } = useContext()
    const router = useRouter()

    const hasLastPath = computed(() =>
      Boolean(app.$accessor.misc.lastRoute.length),
    )

    const goBack = () => {
      router.go(-1)
    }

    return { hasLastPath, goBack }
  },
})
