









import { VueQueryDevTools } from "vue-query/devtools"
import { defineComponent } from "@nuxtjs/composition-api"

import CustomerBottomNav from "@/components/Customer/CustomerBottomNav.vue"
import useBottomNav from "@/composables/useBottomNav"
import useOneSignalSubscription from "@/composables/useOneSignalSubscription"

export default defineComponent({
  components: { CustomerBottomNav, VueQueryDevTools },

  setup() {
    const { direction } = useBottomNav()
    useOneSignalSubscription()

    return {
      direction,
    }
  },
})
