import { Middleware } from "@nuxt/types"
import { computed } from "@nuxtjs/composition-api"

const middleware: Middleware = ({ route, redirect, app }) => {
  let currentPath = route.fullPath
  const currentUser = computed(() => app.$accessor.user.currentUser)
  const currentUserRole = computed(() => app.$accessor.user.currentUserRole)

  const lastIndexOfCurrentPath = currentPath.length - 1

  currentPath =
    currentPath[lastIndexOfCurrentPath] === "/"
      ? currentPath.substring(0, lastIndexOfCurrentPath)
      : currentPath
  const hasNotProfessionalProfileDone =
    Boolean(currentUser.value?.professionalData) === false
  if (
    currentUserRole.value === app.$enums.Roles.PROFESSIONAL &&
    hasNotProfessionalProfileDone &&
    currentPath !== app.$enums.AppPaths.USER_DETAILS &&
    currentPath !== app.$enums.AppPaths.SIGN_UP &&
    currentPath !== app.$enums.AppPaths.SELECT_ROLE
  ) {
    redirect(app.localePath({ path: app.$enums.AppPaths.USER_DETAILS }))
  }

  if (!currentPath.length || currentPath === app.$enums.AppPaths.HOME) {
    let path = app.$enums.AppPaths.SEARCH
    if (currentUserRole.value === app.$enums.Roles.PROFESSIONAL)
      path = app.$enums.AppPaths.MY_CASES
    redirect(app.localePath({ path }))
  }

  if (currentPath === app.$enums.AppPaths.PROFESSIONAL) {
    redirect(app.localePath({ path: app.$enums.AppPaths.MY_CASES }))
  }

  if (currentPath === app.$enums.AppPaths.CUSTOMER) {
    redirect(app.localePath({ path: app.$enums.AppPaths.SEARCH }))
  }

  if (currentPath === app.$enums.AppPaths.CHAT_INDEX) {
    redirect(app.localePath({ path: "/" }))
  }
}

export default middleware
