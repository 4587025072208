import {
  computed,
  onBeforeUnmount,
  onMounted,
  useContext,
} from "@nuxtjs/composition-api"

import { isMobile } from "@/utils/mobileDetection"

function useBottomNav() {
  type IDirection = "up" | "down"
  const { app } = useContext()

  const showNav = computed(() => {
    return app.$accessor.misc.bottomNavShow
  })

  const direction = computed<IDirection>(() => {
    if (showNav.value) return "up"
    return "down"
  })

  onMounted(() => {
    toggleBottomNav(true)
  })

  onBeforeUnmount(() => {
    toggleBottomNav(true)
  })

  const toggleBottomNav = (value: boolean, alwaysToggle: boolean = false) => {
    const toggle = isMobile() || alwaysToggle
    if (toggle) {
      app.$accessor.misc.TOGGLE_BOTTOM_NAV(value)
    }
  }

  let lastScrollTop = 0

  function scrollToggleBottomNav() {
    const st = window.pageYOffset || document.documentElement.scrollTop
    if (st > lastScrollTop) {
      // downscroll code
      app.$accessor.misc.TOGGLE_BOTTOM_NAV(false)
    } else {
      // upscroll code
      app.$accessor.misc.TOGGLE_BOTTOM_NAV(true)
    }
    lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
  }

  return {
    direction,
    toggleBottomNav,
    scrollToggleBottomNav,
  }
}

export default useBottomNav
