import { render, staticRenderFns } from "./public.vue?vue&type=template&id=14c04aff&scoped=true&"
import script from "./public.vue?vue&type=script&lang=ts&"
export * from "./public.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c04aff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelector: require('/workspace/components/LanguageSelector.vue').default,Cookies: require('/workspace/components/Cookies.vue').default,CustomerBottomNav: require('/workspace/components/Customer/CustomerBottomNav.vue').default})
