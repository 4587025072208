export enum EventGoogleAdsIds {
  SIGN_UP = "l4XBCKq3z5EZEI_Kq90q",
  LOGIN = "CscACPm7z5EZEI_Kq90q",
  VERIFY_EMAIL = "UGHuCK_qyJEZEI_Kq90q",
  CASE_OPEN = "XPhzCNLbwJEZEI_Kq90q",
  CASE_MESSAGE = "1sGkCKzkyJEZEI_Kq90q",
  CASE_PAYMENT = "gcPiCLnzz5EZEI_Kq90q",
  SUBSCRIPTION_PAYMENT = "7LCqCIisyZEZEI_Kq90q",

  PAGE_VISIT = "1IMqCKGZoJAZEI_Kq90q",
}
