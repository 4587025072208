/* eslint-disable import/named */
import { BvToast, BvToastOptions } from "bootstrap-vue/src/components/toast"

import { ToastVariants } from "@/enums"
import { isMobile } from "@/utils/mobileDetection"

export default function useToast(toaster: BvToast) {
  const toasterPosition = isMobile()
    ? "b-toaster-top-center"
    : "b-toaster-top-right"
  const createToast = (
    variant: ToastVariants,
    message: string,
    duration: number = 3000,
    options?: BvToastOptions,
  ) => {
    toaster.toast(message, {
      ...options,
      toaster: toasterPosition,
      variant,
      autoHideDelay: duration,
    })
  }

  return { createToast }
}
