import { Context, Middleware } from "@nuxt/types"

const middleware: Middleware = ({ app, redirect, store }: Context) => {
  const hasUser = store.state.user.hasUser

  if (hasUser) {
    redirect(app.localePath({ path: app.$enums.AppPaths.MY_CASES }))
  }
}

export default middleware
