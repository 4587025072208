import { Plugin } from "@nuxt/types"
import googleAnalytics from "@analytics/google-analytics"
import Analytics from "analytics"

const analytics: Plugin = (
  {
    app: {
      $config: { GOOGLE_ANALYTICS_ID },
    },
  },
  inject,
) => {
  inject(
    "analytics",
    Analytics({
      app: "chat-pwa",
      plugins: [
        googleAnalytics({
          measurementIds: [GOOGLE_ANALYTICS_ID],
        }),
      ],
    }),
  )
}

export default analytics
