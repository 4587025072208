





















import {
  computed,
  defineComponent,
  ref,
  useContext,
} from "@nuxtjs/composition-api"

import { useLocaleQuery } from "@/api/query/languagesQuery"
import { Locale } from "@/types/models/Locale"
export default defineComponent({
  setup(_, { emit }) {
    const { app } = useContext()
    const showLanguages = ref(false)
    const currentLanguage = computed(() => app.i18n.locale)
    const languages = ref<Locale[]>()

    const { isLoading } = useLocaleQuery({
      onSuccess: (data) => {
        languages.value = data.filter((locale) => {
          return locale.enabled
        })
      },
    })

    const changeLanguage = (locale: string) => {
      app.i18n.setLocale(locale)
      showLanguages.value = false
      emit("updateLanguage", locale)
    }

    return {
      isLoading,
      languages,
      currentLanguage,
      changeLanguage,
      showLanguages,
    }
  },
})
