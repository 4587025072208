import { Middleware } from "@nuxt/types"
import { computed } from "@nuxtjs/composition-api"

import { UserStatus } from "@/enums"

const middleware: Middleware = ({ app, redirect, route }) => {
  const currentUser = computed(() => app.$accessor.user.currentUser)
  const currentUserRole = computed(() => app.$accessor.user.currentUserRole)

  if (!currentUserRole.value) {
    redirect(
      app.localePath({
        path: app.$enums.AppPaths.SIGN_UP,
        query: { redirectPath: route.path },
      }),
    )
  } else if (currentUser.value?.status === UserStatus.NEW_USER) {
    redirect(
      app.localePath({
        path: app.$enums.AppPaths.YOU_ARE_IN,
        query: { redirectPath: route.path },
      }),
    )
  }
}

export default middleware
