import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _42a9dfbd = () => interopDefault(import('../pages/confirm-email-update.vue' /* webpackChunkName: "pages/confirm-email-update" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0090c240 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _419d77d2 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _181c5944 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d89400dc = () => interopDefault(import('../pages/select-professional.vue' /* webpackChunkName: "pages/select-professional" */))
const _79182561 = () => interopDefault(import('../pages/select-role.vue' /* webpackChunkName: "pages/select-role" */))
const _26a41e65 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _1a5e62b2 = () => interopDefault(import('../pages/types.ts' /* webpackChunkName: "pages/types" */))
const _69bbef79 = () => interopDefault(import('../pages/user-details/index.vue' /* webpackChunkName: "pages/user-details/index" */))
const _7f2d8f04 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _54793d62 = () => interopDefault(import('../pages/you-are-in.vue' /* webpackChunkName: "pages/you-are-in" */))
const _3fd52a2d = () => interopDefault(import('../pages/customer/my-cases.vue' /* webpackChunkName: "pages/customer/my-cases" */))
const _66df7ea4 = () => interopDefault(import('../pages/customer/profile.vue' /* webpackChunkName: "pages/customer/profile" */))
const _54e3a1e6 = () => interopDefault(import('../pages/professional/customers/index.vue' /* webpackChunkName: "pages/professional/customers/index" */))
const _04836984 = () => interopDefault(import('../pages/professional/my-cases.vue' /* webpackChunkName: "pages/professional/my-cases" */))
const _36013a77 = () => interopDefault(import('../pages/professional/profile.vue' /* webpackChunkName: "pages/professional/profile" */))
const _f8e73de2 = () => interopDefault(import('../pages/reset-password/request.vue' /* webpackChunkName: "pages/reset-password/request" */))
const _4ec63464 = () => interopDefault(import('../pages/professional/customers/_id.vue' /* webpackChunkName: "pages/professional/customers/_id" */))
const _3712a6fc = () => interopDefault(import('../pages/chat/_id/index.vue' /* webpackChunkName: "pages/chat/_id/index" */))
const _5bfb59d5 = () => interopDefault(import('../pages/p/_id.vue' /* webpackChunkName: "pages/p/_id" */))
const _1a339c33 = () => interopDefault(import('../pages/chat/_id/payment/index.vue' /* webpackChunkName: "pages/chat/_id/payment/index" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/confirm-email-update",
    component: _42a9dfbd,
    name: "confirm-email-update"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login"
  }, {
    path: "/logout",
    component: _0090c240,
    name: "logout"
  }, {
    path: "/reset-password",
    component: _419d77d2,
    name: "reset-password"
  }, {
    path: "/search",
    component: _181c5944,
    name: "search"
  }, {
    path: "/select-professional",
    component: _d89400dc,
    name: "select-professional"
  }, {
    path: "/select-role",
    component: _79182561,
    name: "select-role"
  }, {
    path: "/sign-up",
    component: _26a41e65,
    name: "sign-up"
  }, {
    path: "/types",
    component: _1a5e62b2,
    name: "types"
  }, {
    path: "/user-details",
    component: _69bbef79,
    name: "user-details"
  }, {
    path: "/verify-email",
    component: _7f2d8f04,
    name: "verify-email"
  }, {
    path: "/you-are-in",
    component: _54793d62,
    name: "you-are-in"
  }, {
    path: "/customer/my-cases",
    component: _3fd52a2d,
    name: "customer-my-cases"
  }, {
    path: "/customer/profile",
    component: _66df7ea4,
    name: "customer-profile"
  }, {
    path: "/professional/customers",
    component: _54e3a1e6,
    name: "professional-customers"
  }, {
    path: "/professional/my-cases",
    component: _04836984,
    name: "professional-my-cases"
  }, {
    path: "/professional/profile",
    component: _36013a77,
    name: "professional-profile"
  }, {
    path: "/reset-password/request",
    component: _f8e73de2,
    name: "reset-password-request"
  }, {
    path: "/professional/customers/:id",
    component: _4ec63464,
    name: "professional-customers-id"
  }, {
    path: "/chat/:id",
    component: _3712a6fc,
    name: "chat-id"
  }, {
    path: "/p/:id?",
    component: _5bfb59d5,
    name: "p-id"
  }, {
    path: "/chat/:id?/payment",
    component: _1a339c33,
    name: "chat-id-payment"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
