export enum Constants {
  MINIMUM_VALUE = 0,
  CASE_FREE_PRICE = 0,
  CASE_PRICE_DEFAULT_EUR = 15,
  DEFAULT_CURRENCY = "EUR",
  CASE_MINIMUM_MONTHS = 1,
  SUBSCRIPTION_PRICE_DEFAULT_EUR = 49,

  MINIMUM_PASSWORD_LENGTH = 8,

  SUBSCRIPTION_FEE_PERCENTAGE = 16,
  MAX_FILE_SIZE_MB = 40,
  NEW_CASE_VALID_HOURS = 48,
  PROFESSIONAL_MINIMUM_AGE = 18,
  CASE_VALID_HOURS_BEFORE_CLOSING = 120,
  ONE_HOUR_IN_MILLISECONDS = 3600000,
  SUBSCRIPTION_DAYS_TO_EXPIRE = 30,
  SINGLE_CASE_PERIOD_IN_DAYS = 5,
  CLOSED = "CLOSED",
  DAYS_TO_SHOW_SUBSCRIPTION_EXPIRATION_NOTIFICATION = 3,
  HEALPHANT_CONTACT_EMAIL = "hey@healphant.com",
  SHARE_LINK_ID_MIN_LENGTH = 6,
  SHARE_LINK_ID_MAX_LENGTH = 24,
  PRIVACY_POLICY_URL = "https://www.healphant.com/privacy_policy/",
  TERMS_OF_SERVICE_URL = "https://www.healphant.com/terms_and_conditions/",
  MAXIMUM_NUMBER_SPECIALITIES = 3,
  MAXIMUM_NUMBER_LANGUAGES = 5,
  MAXIMUM_NUMBER_INTERESTAREAS = 20,
}
