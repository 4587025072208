import { AxiosInstance } from "axios"

import { ApiPaths } from "@/enums"
import { UpdateUserPayload } from "@/types/apiPayloads/UpdateUserPayload"
import { SignUpDataResponse } from "@/types/apiResponses/GetSignUpData"
import { User } from "@/types/models/User"

export const user = (axios: AxiosInstance) => ({
  create: (user: Partial<User>, password: string) =>
    axios.post(ApiPaths.CREATE_USER, {
      ...user,
      password,
    }),
  logIn: (email: string, password: string) => {
    return axios.post<User>(ApiPaths.LOG_IN, {
      email,
      password,
    })
  },
  logOut: () => axios.post(ApiPaths.LOG_OUT),
  requestTokenResetPassword: (email: string) => {
    return axios.post(ApiPaths.REQUEST_TOKEN_RESET_PASSWORD, {
      email,
    })
  },
  resetPassword: (
    email: string,
    newPassword: string,
    resetPasswordToken: string,
  ) => {
    return axios.post<User>(ApiPaths.RESET_PASSWORD, {
      email,
      newPassword,
      resetPasswordToken,
    })
  },
  verifyEmail: (email: string, token: string) =>
    axios.post(ApiPaths.VERIFY_EMAIL, {
      email,
      token,
    }),
  me: () => {
    return axios.get<User>(ApiPaths.GET_USER_ME)
  },

  getSignUpData: (role: string) => {
    return axios.get<SignUpDataResponse>(ApiPaths.GET_SIGNUP_DATA, {
      params: {
        role,
      },
    })
  },
  createProfessional: () => {
    return axios.post<any>(ApiPaths.CREATE_PROFESSIONAL)
  },
  updateUser({
    data,
  }: {
    data: { [key in keyof UpdateUserPayload]?: Partial<UpdateUserPayload[key]> }
  }) {
    return axios.post<User>(ApiPaths.UPDATE_USER, data)
  },

  checkEmailAvailability: (email: string) => {
    return axios.get<boolean>(ApiPaths.CHECK_EMAIL_AVAILABILITY, {
      params: { email },
    })
  },
})
