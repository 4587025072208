import { onMounted } from "@nuxtjs/composition-api"

import { useAddDeviceMutation } from "@/api/query/devicesQuery"

export default function () {
  const { mutate: deviceMutate } = useAddDeviceMutation()

  onMounted(() => {
    window.OneSignal.push(() => {
      window.OneSignal.on(
        "subscriptionChange",
        async (isSubscribed: boolean) => {
          if (!isSubscribed) return
          const deviceId = await window.OneSignal.getUserId()
          if (!deviceId) return

          deviceMutate({
            device: deviceId,
            silent: false,
          })
        },
      )
    })
  })
}
