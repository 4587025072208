import { useContext } from "@nuxtjs/composition-api"
import { intervalToDuration } from "date-fns"

const TWO_DIGIT = "2-digit"

export const formatDate2Digits = (date: string | Date) => {
  const { i18n } = useContext()
  const locale = i18n.locale
  return new Date(date).toLocaleDateString(locale, {
    day: TWO_DIGIT,
    month: TWO_DIGIT,
    year: TWO_DIGIT,
  })
}

export const formatTime2Digits = (date: string, locale: string) => {
  return new Date(date).toLocaleTimeString(locale, {
    hour: TWO_DIGIT,
    minute: TWO_DIGIT,
  })
}

export const convertDate = (dateToFormat: string) => {
  return new Date(Date.parse(dateToFormat))
}

export const formatDate = (date: Date, withTime?: boolean) => {
  const { app } = useContext()
  const locale = app.i18n.locale
  const dateString = date.toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return withTime ? `${dateString} ${getTime(date)}` : dateString
}

export const formatDateNumeric = (date: Date, withTime?: boolean) => {
  const { app } = useContext()
  const locale = app.i18n.locale
  const dateString = date.toLocaleDateString(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })

  return withTime ? `${dateString} ${getTime(date)}` : dateString
}

const getTime = (date: Date) => {
  const { app } = useContext()
  const locale = app.i18n.locale

  return date.toLocaleTimeString(locale, {
    hour: "numeric",
    minute: "numeric",
  })
}

export function getAge(date: string) {
  const birthDate = convertDate(date)
  const { years } = intervalToDuration({
    start: birthDate,
    end: new Date(),
  })

  return years ?? 0
}

export function getDaysBetweenDates(
  date1: Date | string | number,
  date2: Date | string | number = new Date(),
) {
  const date1Date = new Date(date1)
  const date2Date = new Date(date2)

  const difference = Math.abs(date1Date.getTime() - date2Date.getTime())

  const differenceInDays = Math.ceil(difference / (1000 * 3600 * 24))

  return differenceInDays
}

export function getHoursBetweenDates(
  date1: Date | string | number,
  date2: Date | string | number = new Date(),
) {
  const date1Date = new Date(date1)
  const date2Date = new Date(date2)

  const difference = Math.abs(date1Date.getTime() - date2Date.getTime())

  const differenceInHours = Math.ceil(difference / (1000 * 3600))

  return differenceInHours
}
