import Vue from "vue"
// eslint-disable-next-line import/named
import VueGtag, { PluginOptions } from "vue-gtag"
import { Plugin } from "@nuxt/types"

const plugin: Plugin = ({
  app: {
    $config: { GOOGLE_GTAG_KEY },
  },
}) => {
  if (!GOOGLE_GTAG_KEY) {
    return
  }
  Vue.use(VueGtag, {
    config: { id: GOOGLE_GTAG_KEY },
  } as PluginOptions)
}

export default plugin
