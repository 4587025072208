import { Middleware } from "@nuxt/types"
import { computed } from "@nuxtjs/composition-api"

const middleware: Middleware = ({ app, redirect }) => {
  const currentUser = computed(() => app.$accessor.user.currentUser)
  const currentUserRole = computed(() => app.$accessor.user.currentUserRole)

  if (!currentUserRole.value) return

  const isNotNewProfessional =
    Boolean(currentUser.value?.professionalData) === true
  if (
    currentUserRole.value === app.$enums.Roles.PROFESSIONAL &&
    isNotNewProfessional
  ) {
    redirect(app.localePath({ path: app.$enums.AppPaths.MY_CASES }))
  }
}

export default middleware
