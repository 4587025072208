import Vue from "vue"
import {
  extend,
  localeChanged,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate"
import {
  between,
  email,
  min_value as minValue,
  oneOf,
  required,
} from "vee-validate/dist/rules"

import { getAge } from "@/utils/dateTimeFormatter"

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

extend("email", email)

extend("required", required)

extend("isChecked", {
  validate: (value): boolean => {
    return value === true
  },
})

extend("isPasswordEquals", {
  params: ["confirmedPassword"],
  validate: (value, { confirmedPassword }: Record<string, any>): boolean => {
    return value === confirmedPassword
  },
})

extend("min_value", {
  ...minValue,
})

extend("password_length", {
  params: ["min"],
  validate: (value, { min }: Record<string, any>): boolean => {
    return value.length >= min
  },
})

extend("between", between)

extend("casePrice", {
  params: ["field", "freeValue", "min", "max", "currency"],
  validate(value: number, { min, freeValue }: Record<string, any>) {
    if (value === freeValue) {
      return true
    } else if (value !== freeValue && value < min) {
      return false
    } else {
      return true
    }
  },
})

extend("min_age", {
  params: ["min"],
  validate: (value: string, { min }: Record<string, any>) => {
    return getAge(value) >= min
  },
})

extend("iban", {
  validate: (value) => {
    return /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([0-9]{0,3})?$/.test(value)
  },
})

extend("length_between", {
  params: ["field", "min", "max"],
  validate: (value, { min, max }: Record<string, any>) => {
    return value.length >= min && value.length <= max
  },
})

extend("oneOfGender", { ...oneOf })
localeChanged()
