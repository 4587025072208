import { actionTree, getterTree, mutationTree } from "typed-vuex"

interface IState {
  bottomNavShow: boolean
  lastRoute: String
  currentRoute: String
  newlyCreatedProfessional: boolean
  acceptedCookies: boolean
}
export const state = (): IState => ({
  bottomNavShow: true,
  lastRoute: "",
  currentRoute: "",
  newlyCreatedProfessional: false,
  acceptedCookies: localStorage.getItem("acceptedCookies") === "yes",
})

export const getters = getterTree(state, {
  isNewlyCreatedProfessional: (state) => state.newlyCreatedProfessional,
  isAcceptedCookies: (state) => state.acceptedCookies,
})

export const mutations = mutationTree(state, {
  TOGGLE_BOTTOM_NAV: (state, bottomNavStatus: boolean) => {
    state.bottomNavShow = bottomNavStatus
  },
  UPDATE_LAST_ROUTE: (state, path: string) => {
    state.lastRoute = path
  },
  UPDATE_CURRENT_ROUTE: (state, path: string) => {
    state.currentRoute = path
  },
  SET_NEWLY_CREATED_PROFESSIONAL: (state, status: boolean) => {
    state.newlyCreatedProfessional = status
  },
  SET_ACCEPTED_COOKIES: (state, status: boolean) => {
    state.acceptedCookies = status
    if (status) {
      localStorage.setItem("acceptedCookies", "yes")
    }
  },
})

export const actions = actionTree({ state, getters, mutations }, {})
