export enum Events {
  SIGN_UP = "SIGN_UP",
  LOGIN = "LOGIN",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  CASE_OPEN = "CASE_OPEN",
  CASE_MESSAGE = "CASE_MESSAGE",
  CASE_PAYMENT = "CASE_PAYMENT",
  CASE_CLOSE = "CASE_CLOSE",
  SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT",
  SUBSCRIPTION_CLOSE = "SUBSCRIPTION_CLOSE",

  PAGE_VISIT = "PAGE_VISIT",
}
