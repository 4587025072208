export enum CaseStatus {
  CREATED = "CREATED",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  FREE = "FREE",
  PAID = "PAID",
  CLOSED_BY_PROFESSIONAL = "CLOSED_BY_PROFESSIONAL",
  CLOSED_REJECTED = "CLOSED_REJECTED",
  CLOSED_NOT_ANSWERED = "CLOSED_NOT_ANSWERED",
  CLOSED_NOT_PAID = "CLOSED_NOT_PAID",
  CLOSED_EXPIRED = "CLOSED_EXPIRED",
  CLOSED_BY_ADMIN = "CLOSED_BY_ADMIN",
}
