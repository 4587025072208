import { useQueryProvider } from "vue-query"
import { Plugin } from "@nuxt/types"
import { onGlobalSetup } from "@nuxtjs/composition-api"
import { AxiosError } from "axios"

import useMessageApiErrorI18n from "@/composables/useMessageApiError"
import useToast from "@/composables/useToast"
import { ApiError, ApiErrorsList } from "@/types/models/ApiError"
const RETRIES = 5

const plugin: Plugin = ({ app }, _inject) => {
  const { formatErrorMessage, formatErrorList } = useMessageApiErrorI18n()

  const onError = (_error: unknown) => {
    const error = _error as AxiosError<ApiError>
    const errorList = _error as AxiosError<ApiErrorsList>
    let isErrorList = false
    if (Array.isArray(error.response?.data)) {
      isErrorList = true
    }
    const { method, url } = error.config
    const status = error.response?.status
    if (window.$nuxt) {
      const { createToast } = useToast(window.$nuxt.$root.$bvToast)

      createToast(
        app.$enums.ToastVariants.DANGER,
        isErrorList
          ? formatErrorList(errorList, app)[0]
          : formatErrorMessage(error, app),
        4000,
        {
          title: `${app.i18n.t("errorMessages.title")}`,
        },
      )
    }
    app.$sentry.captureException(
      `Network error. [${method?.toUpperCase()}] ${url} [${status}] ` + _error,
    )
  }

  onGlobalSetup(() => {
    useQueryProvider({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: (failureCount, _error) => {
            const error = _error as AxiosError<ApiError>
            const status = error.response?.status

            if (status && status <= 500) {
              return false
            }

            return failureCount <= RETRIES
          },
          onError,
        },
        mutations: {
          onError,
        },
      },
    })
  })
}

export default plugin
