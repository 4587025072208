import { actionTree, getAccessorType, mutationTree } from "typed-vuex"

import * as misc from "@/store/misc"
import * as user from "@/store/user"

export const state = () => ({})

export const getters = {}

export const mutations = mutationTree(state, {})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async nuxtClientInit({ dispatch }) {
      await dispatch("user/fetchCurrentUser")
    },
  },
)

export const accessorType = getAccessorType({
  state,
  getters,
  actions,

  mutations,
  modules: {
    user,
    misc,
  },
})
