






















import { NuxtError } from "@nuxt/types"
import { defineComponent, PropOptions } from "@nuxtjs/composition-api"
import { Severity } from "@sentry/types"

import ErrorPage from "~/components/ErrorPage.vue"

export default defineComponent({
  components: {
    ErrorPage,
  },
  props: {
    error: {
      type: Object,
      required: true,
    } as PropOptions<NuxtError>,
  },
  setup(props) {
    const statusCode = props.error?.statusCode

    return {
      statusCode,
    }
  },
  created() {
    this.$sentry.captureEvent({ ...this.error, level: Severity.Fatal })
  },
})
