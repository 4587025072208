import { Middleware } from "@nuxt/types"
import { computed } from "@nuxtjs/composition-api"

const middleware: Middleware = ({ app, redirect }) => {
  const currentUserRole = computed(() => app.$accessor.user.currentUserRole)

  if (!currentUserRole.value) return

  if (currentUserRole.value === app.$enums.Roles.PROFESSIONAL) {
    redirect(app.localePath({ path: app.$enums.AppPaths.MY_CASES }))
  }
}

export default middleware
