import { useQuery } from "vue-query"
import { useContext } from "@nuxtjs/composition-api"

import { ApiPaths } from "@/enums"
import { Locale } from "@/types/models/Locale"
import { CustomQueryHook } from "@/types/vue-query"

export const useLocaleQuery: CustomQueryHook<void, Locale[]> = (options) => {
  const { $axios } = useContext()

  return useQuery(
    ["getLocalesQuery"],
    async () => {
      const { data } = await $axios.get<Locale[]>(ApiPaths.GET_LOCALES)
      return data
    },
    { ...options, keepPreviousData: true },
  )
}

export const useLanguageQuery: CustomQueryHook<void, Locale[]> = (options) => {
  const { $axios } = useContext()

  return useQuery(
    ["getLanguagesQuery"],
    async () => {
      const { data } = await $axios.get<Locale[]>(ApiPaths.GET_LANGUAGES)
      return data
    },
    { ...options, keepPreviousData: true },
  )
}

export const useProfessionalLanguageQuery: CustomQueryHook<void, Locale[]> = (
  options,
) => {
  const { $axios } = useContext()

  return useQuery(
    ["getProfessionalLanguagesQuery"],
    async () => {
      const { data } = await $axios.get<Locale[]>(
        ApiPaths.GET_PROFESSIONAL_LANGUAGES,
      )
      return data
    },
    { ...options, keepPreviousData: true },
  )
}
